// Define a class for your model
import profileImage from '../assets/base/profile.jpg';

export class User {
    constructor(id = 0, username = '', email = '', user_type = null, gender = '',
                user_type_dict = {title: 'Utenti Tipo', is_admin: false, is_employee: false, is_superuser: false},
                ssn = '', old_password = '', password = '', confirmation = '',
                document_id = '',
                is_active = true, first_name = '', last_name = '', date_of_birth = null,
                profile_frontend = profileImage, profile = '', date_of_birth_string = '',
                address = '', phone_number = '', employee = new Employee(), label = '', branch = '', code = '') {
        this.id = id;
        this.username = username
        this.date_of_birth = date_of_birth
        this.email = email
        this.gender = gender
        this.user_type_dict = user_type_dict
        this.user_type = user_type
        this.ssn = ssn
        this.is_active = is_active
        this.first_name = first_name
        this.last_name = last_name
        this.profile = profile
        this.employee = employee
        this.old_password = old_password
        this.password = password
        this.confirmation = confirmation
        this.profile_frontend = profile_frontend
        this.address = address
        this.phone_number = phone_number
        this.document_id = document_id
        this.document_id = document_id
        this.profile = profile
        this.date_of_birth_string = date_of_birth_string
        this.label = label
        this.branch = branch
        this.code = code
    }

    toFormData() {
        let formData = new FormData()
        formData.append('user.id', this.id);
        formData.append('user.username', this.username)
        formData.append('user.date_of_birth', this.date_of_birth)
        formData.append('user.email', this.email)
        formData.append('user.user_type', this.user_type)
        formData.append('user.ssn', this.ssn)
        formData.append('user.is_active', this.is_active)
        formData.append('user.first_name', this.first_name)
        formData.append('user.last_name', this.last_name)
        formData.append('user.profile', this.profile)
        formData.append('user.password', this.password)
        formData.append('user.confirmation', this.confirmation)
        formData.append('user.gender', this.gender)
        formData.append('user.address', this.address)
        formData.append('user.phone_number', this.phone_number)
        formData.append('user.document_id', this.document_id)
        formData.append('employee.contract_type', this.employee.contract_type)
        formData.append('employee.weekly_hours', this.employee.weekly_hours)
        formData.append('employee.cv', this.employee.cv)
        formData.append('employee.title_of_study', this.employee.title_of_study)
        formData.append('employee.iban', this.employee.iban)
        formData.append('employee.branch', this.employee.branch)
        formData.append('employee.code', this.employee.code)
        formData.append('employee.service_ids', this.employee.service_ids)
        return formData
    }

}

export function userCredentials(user) {
    return {
        id: user.id,
        username: user.username,
        email: user.email,
        old_password: user.old_password,
        password: user.password,
        confirmation: user.confirmation,
        confirmed_password: user.confirmation
    }
}

export function userPersonalData(user) {
    return {
        id: user.id,
        first_name: user.first_name,
        last_name: user.last_name,
        date_of_birth: user.date_of_birth,
        gender: user.gender,
        address: user.address,
        phone_number: user.phone_number,
        ssn: user.ssn,
    }
}


class Employee {
    constructor(id = 0, contract_type = '', weekly_hours = null, cv = '', service_name = '', services = [],
                service_ids = [], title_of_study = '', title_of_study_path = '', iban = '', fields_to_change = {}, availability = {},
                branch =  '', code = '') {
        this.id = id
        this.contract_type = contract_type
        this.weekly_hours = weekly_hours
        this.cv = cv
        this.title_of_study_path = title_of_study_path
        this.title_of_study = title_of_study
        this.iban = iban
        this.fields_to_change = fields_to_change
        this.service_name = service_name
        this.availability = availability
        this.services = services
        this.branch = branch
        this.code = code
        this.service_ids = service_ids
    }
}